import { useEffect, useState } from 'react';
import { fetchAndBuildRecommendedVehicleTiles } from '../utilities/recommendationsBuilder';
import { IVehicleTile } from '../../../types/vehicleTile';
import { getSavedVehicles } from '../utilities/savedVehicles';
import { useHomeStore } from '../HeroRefactor/Store';
import { IHomeBase } from '../HeroRefactor/Store/models';
import { CarTilesCarousel } from '../../../components/carousels/car-tiles/CarTilesCarousel/CarTilesCarousel';

export const YouMightLikeContainer = () => {
    const [hasLoadedRecommendedVehicles, setHasLoadedRecommendedVehicles] = useState(false);
    const [isLoadingRecommendedVehicles, setIsLoadingRecommendedVehicles] = useState(false);
    const [recommendedVehiclesList, setRecommendedVehiclesList] = useState<IVehicleTile[]>([]);
    const [favoriteVehicles, setFavoriteVehicle] = useState<number[]>([]);
    const { experienceData, isLoading } = useHomeStore();

    useEffect(() => {
        if (experienceData && !isLoading && !hasLoadedRecommendedVehicles) {
            getSavedVehicles((stockNumberList) => {
                setFavoriteVehicle(stockNumberList);
            });

            setIsLoadingRecommendedVehicles(true);

            fetchAndBuildRecommendedVehicleTiles(
                (recommendedVehicleTileList: IVehicleTile[]) => {
                    if (recommendedVehicleTileList.length > 0) {
                        setRecommendedVehiclesList(recommendedVehicleTileList);
                        setHasLoadedRecommendedVehicles(true);
                        setIsLoadingRecommendedVehicles(false);
                    }
                },
                favoriteVehicles,
                (experienceData as IHomeBase).viewedVehicles as IVehicleTile[]
            );

            setHasLoadedRecommendedVehicles(true);
        }
    }, [hasLoadedRecommendedVehicles, isLoading, experienceData, favoriteVehicles, recommendedVehiclesList]);

    return hasLoadedRecommendedVehicles && recommendedVehiclesList.length > 0 ? (
        <>
            <CarTilesCarousel
                isLoading={isLoadingRecommendedVehicles}
                title="You might like"
                carouselId="you-might-like"
                vehiclesData={recommendedVehiclesList}
                isRemoveEnabled={false}
                sectionName="You might like"
            />
        </>
    ) : (
        <></>
    );
};
