import * as React from 'react';
import { gsap } from 'gsap';
import Carousel from './Carousel';
import { FBSContext } from '../../../context';
import { IFBSDecision } from '../../../api/types/finance';
import { shiftUnavailableVehiclesToEnd } from '../../../pages/home/utilities/helpers';
import { IVehicleTile } from '../../../types/vehicleTile';
import { CarTileWrapper } from '../../../pages/home/hero/car-tile/CarTileWrapper';
import { MiniHomebaseSlideCTA } from '../../../pages/buying-online/components/MiniHomebaseSlideCTA';
import { ANALYTICS_CONSTANTS } from '../../../utilities/analytics';

declare global {
    const featureFlags: any;
}

interface IReturnVisitorCarouselProps {
    id: string;
    isVisible: boolean;
    vehicleTileList: IVehicleTile[];
    vehicleTileLinkAttrs?: { [key: string]: any };
    analyticsName: string;
    carouselName: string;
    favoritedStockNumberList: number[];
    stockNumbersPendingFavoritesUpdate: number[];
    ariaLabel?: string;
    updatedViewedTiles?: (tileToRemove: IVehicleTile) => any;
    enableRemove: boolean;
    fbsData?: IFBSDecision[];
    isMiniHomebase?: boolean;
    onOpenSnackBar: (text: string) => void;
    sectionName: string;
}

interface IReturnVisitorCarouselState {
    carouselItems: React.ReactElement[];
    vehicleTileRefs: HTMLElement[];
}

export default class ReturnVisitorCarousel extends React.Component<
    IReturnVisitorCarouselProps,
    IReturnVisitorCarouselState
> {
    static contextType?: React.Context<any> | undefined = FBSContext;
    context!: React.ContextType<typeof FBSContext>;

    constructor(props: any) {
        super(props);
        this.state = {
            carouselItems: this.buildCarouselItems(),
            vehicleTileRefs: [],
        };

        this.buildCarouselItems = this.buildCarouselItems.bind(this);
        this.addToRefsList = this.addToRefsList.bind(this);
    }

    private addToRefsList(tileRef: HTMLElement) {
        this.setState((currState) => ({ vehicleTileRefs: [...currState.vehicleTileRefs, tileRef] }));
    }

    private buildCarouselItems(): React.ReactElement[] {
        if (!this.props.vehicleTileList?.length) {
            return [];
        }

        const sortedVehicleList: IVehicleTile[] = shiftUnavailableVehiclesToEnd(
            this.props.vehicleTileList as IVehicleTile[]
        );

        const carouselItems = sortedVehicleList
            .filter((item: any) => item !== null)
            .map((vehicle: IVehicleTile, index: number) => {
                const { stockNumber }: IVehicleTile = vehicle;

                return (
                    <div
                        className="carousel__slide"
                        key={`${stockNumber}-slide-${index}`}
                        data-ymm={`${vehicle.year || ''} ${vehicle.make} ${vehicle.model} ${vehicle.trim}`}
                        data-stocknumber={`${vehicle.stockNumber}`}
                    >
                        <div className="carousel-slide-item">
                            <CarTileWrapper
                                vehicle={vehicle as IVehicleTile}
                                updatedViewedTiles={this.props.updatedViewedTiles}
                                enableRemove={this.props.enableRemove}
                                isWaitingForFavoritesResponse={
                                    this.props.stockNumbersPendingFavoritesUpdate.indexOf(stockNumber) > -1
                                }
                                sectionName={this.props.sectionName}
                                allStockNumbers={sortedVehicleList.map((v) => v.stockNumber)}
                                carouselId={this.props.id}
                            />
                        </div>
                    </div>
                );
            });

        if (this.props.isMiniHomebase) {
            const headline = carouselItems.length > 1 ? 'None of these quite right?' : 'Not quite right?';
            const hasFbsData = this.props.fbsData && this.props.fbsData.length > 0;
            carouselItems.push(
                <MiniHomebaseSlideCTA key={'pusher-slide'} headline={headline} hasFbsData={hasFbsData} />
            );
        }

        return carouselItems;
    }

    public componentDidUpdate(prevProps: IReturnVisitorCarouselProps, prevState: IReturnVisitorCarouselState) {
        const favoritedStockNumberListHasChanged =
            JSON.stringify(this.props.favoritedStockNumberList) !== JSON.stringify(prevProps.favoritedStockNumberList);
        const vehicleTileListHasChanged =
            JSON.stringify(this.props.vehicleTileList) !== JSON.stringify(prevProps.vehicleTileList);
        const pendingFavoritesUpdates =
            JSON.stringify(this.props.stockNumbersPendingFavoritesUpdate) !==
            JSON.stringify(prevProps.stockNumbersPendingFavoritesUpdate);
        const isVisibleHasChanged = prevProps.isVisible !== this.props.isVisible;
        const fbsTermsHaveChanged = JSON.stringify(this.props.fbsData) !== JSON.stringify(prevProps.fbsData);
        if (
            vehicleTileListHasChanged ||
            favoritedStockNumberListHasChanged ||
            pendingFavoritesUpdates ||
            fbsTermsHaveChanged
        ) {
            this.setState(
                {
                    carouselItems: this.buildCarouselItems(),
                },
                () => {
                    if (this.props.fbsData && this.props.fbsData.length && this.props.fbsData[0].stockNumber > 0) {
                        setTimeout(() => {
                            const carouselElement = document.getElementById('viewed-return-visitor-carousel-slides-mw');
                            const homeBaseHeroElement = document.getElementById('home-base-hero');
                            const tileTermElements = this.state.vehicleTileRefs.map((tile) =>
                                tile.querySelector('.sc--fbs-terms')
                            );
                            const tabElements = homeBaseHeroElement?.querySelectorAll<HTMLElement>('.hp-tab-content');
                            const firstTabElement = tabElements && tabElements[0];
                            const fbsStylesAreSet =
                                firstTabElement && firstTabElement?.getAttribute('style')?.includes('min-height:');
                            const animFbsIntro = gsap
                                .timeline({ paused: true })
                                .add(() => homeBaseHeroElement?.classList.add('home-base-hero-fbs-height'))
                                .to(
                                    carouselElement,
                                    { duration: 0.15, height: 415, ease: 'expo.inOut' },
                                    fbsStylesAreSet ? 1.2 : 0.3
                                )
                                .to(
                                    this.state.vehicleTileRefs,
                                    { duration: fbsStylesAreSet ? 0.35 : 0.15, translateY: -12, ease: 'ease.inOut' },
                                    '<'
                                )
                                .to(
                                    this.state.vehicleTileRefs,
                                    { duration: 0.025, translateY: 0, stagger: 0.05, ease: 'expo.inOut' },
                                    '>0.3'
                                )
                                .to(
                                    this.state.vehicleTileRefs,
                                    { duration: 0.01, height: 415, stagger: 0.05, ease: 'expo.inOut' },
                                    '<'
                                )
                                .to(
                                    tileTermElements,
                                    { duration: 0.5, autoAlpha: 1, stagger: 0.05, ease: 'expo.inOut' },
                                    '-=0.25'
                                )
                                .add(() => {
                                    if (!fbsStylesAreSet) {
                                        const tabHeight = firstTabElement?.clientHeight ?? 0;
                                        tabElements?.forEach((t) => (t.style.minHeight = `${tabHeight}px`));
                                    }
                                });
                            gsap.timeline({ delay: 0 }).fromTo(
                                animFbsIntro,
                                { progress: 0 },
                                { duration: 2, progress: 1, ease: 'none' }
                            );
                        }, 0);
                    }
                }
            );
        } else {
            if (isVisibleHasChanged) {
                this.setState({
                    carouselItems: this.buildCarouselItems(),
                });
            }
        }
    }

    public render(): React.ReactNode {
        return (
            <div
                id={this.props.id}
                className="carousel-container"
                data-vehicle-count={this.state.carouselItems.length}
                data-saleable-vehicle-count={this.props.vehicleTileList.filter((item) => item.isSaleable).length}
            >
                <div className="home-base-hero--grid-container">
                    <Carousel
                        name={`${this.props.carouselName}-return-visitor`}
                        nextFocus="#budget-calculator-section"
                        ariaLabel={this.props.ariaLabel}
                    >
                        {this.state.carouselItems}
                    </Carousel>
                </div>
            </div>
        );
    }
}
