import { useEffect, useState, MouseEvent, MouseEventHandler } from 'react';
import { useShallow } from 'zustand/react/shallow';
import SVGs from '../../buying-online/utils/svgs';
import { useLocalCarsStore } from '../../../context/localCarsStore';
import { CarTilesCarousel } from '../../../components/carousels/car-tiles/CarTilesCarousel';
import { LocalCarsPlaceholderCarousel } from './LocalCarsPlaceholderCarousel';
import { ANALYTICS_CONSTANTS, trackAnchorClick, trackEDDL } from '../../../utilities/analytics';

interface ShopAllCarsButtonProps {
    isFullWidth?: boolean;
}

const ShopAllCarsButton = ({ isFullWidth }: ShopAllCarsButtonProps) => {
    return (
        <hzn-button
            variant="secondary"
            full-width={isFullWidth}
            onClick={(e) => {
                trackAnchorClick({
                    clickEvent: e as MouseEvent,
                    taggingEvent: ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA,
                    title: 'shop all local cars',
                    position: 'shop cars near me',
                    href: '/cars?distance=50',
                    page: 'Homepage',
                    search: {
                        initiationMethod: `Homepage|shop all local cars|shop cars near me`,
                    },
                });
            }}
        >
            Shop all local cars
        </hzn-button>
    );
};

export const LocalCars = () => {
    const {
        localVehicles,
        setLocalVehicles,
        isLocalVehiclesLoading,
        setFavoriteVehicles,
        setCiam,
        zipCode,
    } = useLocalCarsStore(
        useShallow((state) => ({
            localVehicles: state.localVehicles,
            setLocalVehicles: state.setLocalVehicles,
            isLocalVehiclesLoading: state.isLocalVehiclesLoading,
            setFavoriteVehicles: state.setFavoriteVehicles,
            setCiam: state.setCiam,
            zipCode: state.zipCode,
        }))
    );

    const [isTestActive, setIsTestActive] = useState(false);

    useEffect(() => {
        setCiam();
        setLocalVehicles();
        setFavoriteVehicles();
    }, [setCiam, setLocalVehicles, setFavoriteVehicles]);

    // Add listener for kmxEnableLocalCarsTest
    useEffect(() => {
        const handleKmxEnableLocalCarsTest = () => setIsTestActive(true);

        document.addEventListener('kmxEnableLocalCarsTest', handleKmxEnableLocalCarsTest);

        return () => {
            document.removeEventListener('kmxEnableLocalCarsTest', handleKmxEnableLocalCarsTest);
        };
    }, []);

    useEffect(() => {
        if (localVehicles?.length && !isLocalVehiclesLoading) {
            trackEDDL({
                event: ANALYTICS_CONSTANTS.EVENTS.IMPRESSION.RECOMMENDED_CAR,
                recommendations: {
                    stockNumber: '',
                    carouselLength: String(localVehicles.length),
                    browse: localVehicles.map((vehicle) => vehicle.stockNumber).join(','),
                },
                module: {
                    moduleExperience: 'Shop cars near me',
                },
            });
        }
    }, [localVehicles, isLocalVehiclesLoading]);

    const openStoreSelectionModal = () => {
        const detail = { stateOrZip: zipCode };

        // Analytics
        trackEDDL({
            event: 'storeChange',
            linkDetails: {
                name: zipCode || '',
                position: 'Shop cars near you',
            },
        });

        // Open modal
        const event = new CustomEvent('kmxOpenStoreSelectionModal', { detail });
        document.documentElement.dispatchEvent(event);
    };

    const renderCarousel = () => {
        return isLocalVehiclesLoading ? (
            <LocalCarsPlaceholderCarousel isLoading={isLocalVehiclesLoading} />
        ) : localVehicles && localVehicles?.length > 0 ? (
            <CarTilesCarousel
                sectionName="Shop cars near me"
                carouselId="local-cars-carousel"
                vehiclesData={isTestActive ? localVehicles.slice(0, 6) : localVehicles}
                nextFocus="online-offer"
                isRemoveEnabled={false}
                showRelatedSearchesTile={isTestActive}
            />
        ) : (
            <LocalCarsPlaceholderCarousel isLoading={isLocalVehiclesLoading} />
        );
    };

    return (
        <>
            {isTestActive && (
                <div id="local-cars-container" className="active-test">
                    <div className="local-cars--heading">
                        <div>
                            <h2>Shop cars near me</h2>
                            <button
                                type="button"
                                className="zip-code"
                                onClick={openStoreSelectionModal}
                                aria-haspopup="true"
                                aria-label="Change your CarMax store"
                            >
                                {SVGs.locationMUIIcon} {zipCode}
                            </button>
                        </div>
                        <ShopAllCarsButton />
                    </div>
                    {renderCarousel()}
                    <div className="shop-all-button-container">
                        <ShopAllCarsButton isFullWidth />
                    </div>
                </div>
            )}
            {!isTestActive && (
                <div id="local-cars-container">
                    <div className="local-cars--heading">
                        <h2>Shop cars near me</h2>
                        <button
                            type="button"
                            className="zip-code"
                            onClick={openStoreSelectionModal}
                            aria-haspopup="true"
                            aria-label="Change your CarMax store"
                        >
                            {SVGs.locationIcon} {zipCode}
                        </button>
                    </div>
                    {renderCarousel()}
                </div>
            )}
        </>
    );
};
