import { CarTile, ModalHandler } from '@kmx/car-tile';
import { IVehicleTile } from '../../../../types/vehicleTile';
import { vehicleIsUnavailable, buildStatusText } from '../../../../utilities/vehicleTileBuilder';
import { mapVehicleTileToCar } from './carTileUtils';
import { useEffect, useState, useContext, useRef } from 'react';
import Icons from '../../../../utilities/icons';
import { getCarImages } from '../../../../api/getCarImages';
import { FBSContext } from './../../../../context/';
import { IFBSDecision } from '../../../../api/types/finance';
import * as analytics from '../../../../utilities/analytics';
import TileKebabMenuItemSnackbar from '../tile-kebab-menu/TileKebabMenuItemSnackbar';
import { useLocalCarsStore } from '../../../../context/localCarsStore';
import { useShallow } from 'zustand/react/shallow';
import { ANALYTICS_CONSTANTS, trackEDDL } from '../../../../utilities/analytics';
import { ITrackingPayload } from '../../../../types/analytics';
import { VehicleDetailsDialog } from './VehicleDetailsDialog';

interface ICarTileWrapperProps {
    vehicle: IVehicleTile;
    updatedViewedTiles?: (VehicleTile: IVehicleTile) => any;
    enableRemove: boolean;
    isWaitingForFavoritesResponse: boolean;
    isCompact?: boolean;
    sectionName: string;
    allStockNumbers: number[];
    carouselId: string;
}

export const CarTileWrapper = ({
    vehicle,
    updatedViewedTiles,
    enableRemove,
    isCompact = true,
    sectionName,
    carouselId,
    allStockNumbers,
}: ICarTileWrapperProps) => {
    const ref = useRef(null);
    const car = mapVehicleTileToCar(vehicle);
    const [isFavoriteInUI, setIsFavoriteInUI] = useState<boolean>(false);
    const [snackbar, setSnackBar] = useState<{ icon: any; text: string; isOpen: boolean }>({
        icon: undefined,
        text: '',
        isOpen: false,
    });
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const ribbonText = buildStatusText(vehicle);
    const fbsContext = useContext(FBSContext);

    const mapDecisionToFbsApplication = (decision: IFBSDecision) => {
        const {
            applicationUrl,
            fbsDecision: { downPayment, expirationDateFormatted, stateAbbreviation, isSoftPull, tradeInBreakdown },
        } = decision;
        return {
            downPayment: downPayment,
            stateAbbreviation: stateAbbreviation,
            stationUrl: applicationUrl,
            expirationDateFormatted: expirationDateFormatted,
            isSoftPull: isSoftPull,
            tradeInBreakdown,
        };
    };

    const { favoriteVehicles, addVehicleToFavorites, removeVehicleFromFavorites } = useLocalCarsStore(
        useShallow((state) => ({
            favoriteVehicles: state.favoriteVehicles,
            addVehicleToFavorites: state.addVehicleToFavorites,
            removeVehicleFromFavorites: state.removeVehicleFromFavorites,
        }))
    );

    const decision = fbsContext.fbsData.find((d) => d.stockNumber === vehicle.stockNumber);
    let fbsApplication: object | undefined;
    if (decision) {
        fbsApplication = mapDecisionToFbsApplication(decision);
    }

    const getMenuItems = (vehicle: IVehicleTile) => {
        const menuItems = [
            {
                text: `${isFavoriteInUI ? 'Remove from' : 'Add to'} favorites`,
                icon: Icons.favoriteHeart,
                onClick: () => {
                    handleHeartClick();
                },
                className: `tile-menu-item--favorite ${isFavoriteInUI ? 'saved' : ''}`,
            },
            {
                text: 'Share',
                icon: Icons.share,
                onClick: () => {
                    handleShareClick();
                    const payload: ITrackingPayload = {
                        event: ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA,
                        linkDetails: {
                            name: 'share',
                            position: vehicle.stockNumber.toString(),
                        },
                    };
                    analytics.trackEDDL(payload);
                },
                className: 'tile-menu-item--share',
            },
        ];

        if (vehicle.state) {
            menuItems.push({
                text: 'Features & specs',
                icon: Icons.AdvancedFeaturesIcon,
                onClick: () => {
                    const payload: ITrackingPayload = {
                        event: ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA,
                        linkDetails: {
                            name: 'Features & specs',
                            position: vehicle.stockNumber.toString(),
                        },
                    };
                    analytics.trackEDDL(payload);

                    setIsDialogVisible(true);
                },
                className: 'tile-menu-item--features',
            });
        }

        return menuItems;
    };

    const CRITICAL_MENU_ITEM = {
        text: 'Remove',
        icon: Icons.remove,
        onClick: () => {
            handleRemoveVehicleTile();
            const name = 'remove';
            const event = ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA;
            const payload: ITrackingPayload = {
                event: event,
                linkDetails: {
                    name: name,
                    position: vehicle.stockNumber.toString(),
                },
            };
            analytics.trackEDDL(payload);
        },
        className: 'tile-menu-item--remove',
    };

    useEffect(() => {
        setIsFavoriteInUI(favoriteVehicles.includes(vehicle.stockNumber));
    }, [favoriteVehicles, vehicle.stockNumber]);

    const handleHeartClick = () => {
        setIsFavoriteInUI(isFavoriteInUI);

        if (isFavoriteInUI) {
            removeVehicleFromFavorites(vehicle.stockNumber);
        } else {
            addVehicleToFavorites(vehicle.stockNumber);
        }
    };

    // Wrap the get request so we can indirectly provide the built in image carousel with our context env.
    const getImagesWithContext = (
        stockNumber: any,
        successCallback: (response: any) => void,
        errorCallback: (error: any) => void
    ) => {
        return getCarImages(stockNumber, successCallback, errorCallback);
    };

    const handleRemoveVehicleTile = async () => {
        if (updatedViewedTiles) {
            updatedViewedTiles(vehicle);
        }
        removeVehicleFromFavorites(vehicle.stockNumber, true);
    };

    const handleShareClick = async () => {
        if (navigator.share) {
            navigator
                .share({
                    title: `${document.title}.`,
                    text: `Check out ${document.title}.`,
                    url: `${window.location.origin}/car/${vehicle.stockNumber}`,
                })
                .then(() => console.log('Successful Car Tile Share'))
                .catch((error) => console.log('Error Sharing Car Tile', error));
        } else {
            await navigator.clipboard.writeText(`${window.location.origin}/car/${vehicle.stockNumber}`);
            setSnackBar({ icon: Icons.share, isOpen: true, text: 'Link copied to your clipboard' });
        }
    };

    const handleOnMenuOpen = () => {
        const payload: ITrackingPayload = {
            event: ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA,
            linkDetails: {
                name: 'three dots',
                position: String(vehicle.stockNumber),
            },
        };
        analytics.trackEDDL(payload);
    };

    useEffect(() => {
        const onCarTileClick = (e: Event) => {
            const closestAElement = (e.target as HTMLElement)?.closest('a')?.classList;
            const isCarTileAnchor =
                closestAElement?.contains('scct--make-model-info-link') ||
                closestAElement?.contains('scct--image-gallery__image-link');

            if (isCarTileAnchor) {
                e.preventDefault();

                const isRecommendedCarTile = ANALYTICS_CONSTANTS.RECOMMENDED_SECTION_IDS.includes(carouselId);
                const event = isRecommendedCarTile
                    ? ANALYTICS_CONSTANTS.EVENTS.CLICK.RECOMMENDED_CARTILE
                    : ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA;

                const payload: ITrackingPayload = {
                    event,
                    linkDetails: {
                        name: [car.year, car.make, car.model, car.trim].filter((item) => item).join(' '),
                        position: sectionName,
                    },
                };

                if (isRecommendedCarTile) {
                    payload.recommendations = {};
                    const allStockNumbersJoined = allStockNumbers.join('|');
                    switch (sectionName) {
                        case 'local-cars-carousel':
                            // "browse" signifies an unrelated list of vehicles
                            payload.recommendations.browse = allStockNumbersJoined;
                            break;
                        case 'kmx-recommended-carousel':
                            // "similarVehicles" signifies a related list of vehicles
                            payload.recommendations.similarVehicles = allStockNumbersJoined;
                            break;
                        case 'you-might-like':
                            payload.recommendations.similarVehicles = allStockNumbersJoined;
                            break;
                    }

                    payload.recommendations.recommendedCarClicked = `${car.stockNumber}`;
                    payload.recommendations.stockNumber = `${car.stockNumber}`;
                    payload.module = { moduleExperience: `VR|Homepage|${sectionName}` };
                }

                trackEDDL(payload);

                setTimeout(() => {
                    window.location.href = `/car/${car.stockNumber}`;
                }, ANALYTICS_CONSTANTS.TRACKING_DELAY);
            }
        };

        // Add event listener to CarTile
        const tile = ref.current as HTMLElement | null;

        if (tile) {
            tile.addEventListener('click', onCarTileClick);
            return () => {
                tile.removeEventListener('click', onCarTileClick);
            };
        }
    }, [sectionName, car.stockNumber, car.year, car.make, car.model, car.trim]);

    return (
        <div ref={ref}>
            <ModalHandler>
                <CarTile
                    car={car}
                    disableScrollNav={false}
                    imageUrl={vehicle.imageLink}
                    showFavorites={true}
                    isDisabled={vehicleIsUnavailable(vehicle.statusCode, vehicle.isSaleable)}
                    ribbonStyle="standard"
                    ribbonText={ribbonText}
                    isFavorite={isFavoriteInUI}
                    menuItems={getMenuItems(vehicle)}
                    criticalMenuItem={enableRemove ? CRITICAL_MENU_ITEM : undefined}
                    onFavorite={handleHeartClick}
                    onUnfavorite={handleHeartClick}
                    getCarImages={getImagesWithContext}
                    fbsApplication={fbsApplication}
                    fbsDecision={decision?.fbsDecision}
                    downPayment={decision?.fbsDecision.downPayment}
                    recommendationType={vehicle.recommendationType}
                    onMenuOpen={handleOnMenuOpen}
                    isCompact={isCompact}
                    hasPriceDrop={vehicle.hasPriceDrop}
                    originalPrice={vehicle.originalPrice}
                    showElectricVehicleInfo
                />
            </ModalHandler>

            <VehicleDetailsDialog
                isVisible={isDialogVisible}
                vehicle={vehicle}
                setIsDialogVisible={setIsDialogVisible}
            />

            <TileKebabMenuItemSnackbar
                icon={snackbar.icon}
                isOpen={snackbar.isOpen}
                textLabel={snackbar.text}
                cancelAction={() => setSnackBar({ icon: undefined, isOpen: false, text: '' })}
            />
        </div>
    );
};
